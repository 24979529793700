import React from "react";
import Header from "../../components/header/header";
import Hero from "../../components/hero/Hero";
import Adverts from "../../components/advert/Adverts";
import Footer from "../../components/footer/Footer";
import PublishNow from "../../components/PublishNow/PublishNow";
import ContentWrapper from "../../components/content-wrapper/ContentWrapper";
import dash1 from "../../common/assets/img/dash1.png";
import dash2 from "../../common/assets/img/dash2.png";

function Dashboard() {
  return (
    <>
      <ContentWrapper>
        <Header />
        <div style={{ marginInline:"2rem" }}>
        <h1 className="section-title">Prochainement sur Allô Démarcheur</h1>
        <p style={{ textAlign:"center",marginBottom:"1rem" }}>Dans notre prochaine mise à jour, découvrez votre tableau (voici un aperçu 👇🏾)</p>

          <img src={dash1} style={{ width:"100%",marginBottom:"2rem" }}/>
          
          <img src={dash2} style={{ width:"100%" }}/>
        </div>
        {/* <Footer /> */}
      </ContentWrapper>
    </>
  );
}

export default Dashboard;

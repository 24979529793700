import React, { useState, useEffect } from "react";
import "./AdvertFilters.css";
import { typeBiens, villesTogo } from "../../common/data/data";
import jwtDecode from "jwt-decode";

function AdvertFilters({ updateFiltersState }) {
  const [selectedTag, setSelectedTag] = useState("all");
  const [selectedTrie, setSelectedTrie] = useState("date");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedCity, setSelectedCity] = useState("all");
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });
  const [selectedPostedSince, setSelectedPostedSince] = useState("none");
  const [mineOnly, setMineOnly] = useState(false);
  const [validUserToken, setValidUserToken] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("dem_user"));
    const tokens = JSON.parse(localStorage.getItem("dem_tokens"));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
    const currentTime = Date.now() / 1000;
    const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    if (tokens && tokens.access && isValidAccessToken) {
      setValidUserToken(!!user);
    }
  }, [validUserToken]);

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    // updateFiltersState({ ...updateFiltersState, tag: tag });
  };

  const handleTrieChange = (event) => {
    setSelectedTrie(event.target.value);
    // updateFiltersState({ ...updateFiltersState, trie: event.target.value });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    // updateFiltersState({ ...updateFiltersState, category: event.target.value });
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    // updateFiltersState({ ...updateFiltersState, city: event.target.value });
  };

  const handlePriceRangeChange = (event, field) => {
    setPriceRange((prevPriceRange) => ({
      ...prevPriceRange,
      [field]: event.target.value,
    }));
  };

  const handlePostedSinceChange = (event) => {
    setSelectedPostedSince(event.target.value);
    // updateFiltersState({ ...updateFiltersState, postedSince: event.target.value });
  };

  const handleMineOnlyChange = (event) => {
    setMineOnly(event.target.checked);
    // updateFiltersState({ ...updateFiltersState, mineOnly: event.target.checked });
  };

  useEffect(() => {
    // Update the filters object when any of the filter state changes
    const filters = {
      trie: selectedTrie,
      tag: selectedTag,
      category: selectedCategory,
      city: selectedCity,
      postedSince: selectedPostedSince,
      priceRange: priceRange,
      mineOnly: mineOnly,
    };
    updateFiltersState(filters);
  }, [
    selectedTrie,
    selectedTag,
    selectedCategory,
    selectedCity,
    priceRange,
    selectedPostedSince,
    mineOnly,
  ]);

  return (
    <nav className="filters-wrapper">
      <div className="tag-box">
        <span
          className={`tag searching-tag ${
            selectedTag === "all" ? "active" : ""
          }`}
          onClick={() => handleTagClick("all")}
        >
          Toutes
        </span>
        <span
          className={`tag searching-tag ${
            selectedTag === "searching" ? "active" : ""
          }`}
          onClick={() => handleTagClick("searching")}
        >
          Je cherche
        </span>
        <span
          className={`tag selling-tag ${
            selectedTag === "selling" ? "active" : ""
          }`}
          onClick={() => handleTagClick("selling")}
        >
          Je vends
        </span>
        <span
          className={`tag lending-tag ${
            selectedTag === "lending" ? "active" : ""
          }`}
          onClick={() => handleTagClick("lending")}
        >
          Je loue
        </span>
      </div>
      {/* <div className="text-divider">Trier par</div>
      <div className="tries-box">
        <label className="trie">
          <input
            type="radio"
            name="trie"
            value="date"
            checked={selectedTrie === "date"}
            onChange={handleTrieChange}
          />
          Date (plus récent d'abord)
        </label>
        <label className="trie">
          <input
            type="radio"
            name="trie"
            value="lower-price"
            checked={selectedTrie === "lower-price"}
            onChange={handleTrieChange}
          />
          Prix bas d'abord
        </label>
        <label className="trie">
          <input
            type="radio"
            name="trie"
            value="upper-price"
            checked={selectedTrie === "upper-price"}
            onChange={handleTrieChange}
          />
          Prix élevé d'abord
        </label>
      </div> */}
      <div className="text-divider">Localisation et Catégorie</div>
      <div className="local-categ-box">
        <div className="categ">
          <label htmlFor="category">Catégorie:</label>
          <select
            id="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="all">Toutes</option>
            {typeBiens.map((category) => (
              <option key={category.value} value={category.value}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
        <div className="local">
          <label htmlFor="city">Localisation:</label>
          <select id="city" value={selectedCity} onChange={handleCityChange}>
            <option value="all">Toutes</option>
            {/* <option value="lome">Lomé</option>
            <option value="kpalime">Kpalimé</option>
            <option value="kara">Kara</option> */}
            {villesTogo.sort().map((ville, index) => (
              <option key={index} value={ville}>
                {ville}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="text-divider">Prix compris entre</div>
      <div className="price-range">
        <input
          type="number"
          placeholder="Min"
          value={priceRange.min}
          onChange={(event) => handlePriceRangeChange(event, "min")}
        />
        <input
          type="number"
          placeholder="Max"
          value={priceRange.max}
          onChange={(event) => handlePriceRangeChange(event, "max")}
        />
      </div>
      <div className="text-divider">Posté il y a moins de</div>
      <div className="posted-since">
        <select
          id="postedSince"
          value={selectedPostedSince}
          onChange={handlePostedSinceChange}
        >
          <option value="">Indifférent</option>
          <option value="1-hour">1 Heure</option>
          <option value="24-hour">24 Heures</option>
          <option value="7-days">7 Jours</option>
          <option value="30-days">30 Jours</option>
        </select>
      </div>
      {validUserToken && (
        <>
          <div className="text-divider">Mes annonces uniquement</div>
          <div className="mine-only">
            <label htmlFor="mineOnly">Mes annonces :</label>
            <input
              type="checkbox"
              id="mineOnly"
              checked={mineOnly}
              onChange={handleMineOnlyChange}
            />
          </div>
        </>
      )}
      {/* <hr /> */}
    </nav>
  );
}

export default AdvertFilters;

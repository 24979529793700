import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../services/api";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/header/header";
import ContentWrapper from "../../components/content-wrapper/ContentWrapper";
import { register } from "swiper/element/bundle";
import "./SingleAdvert.css";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import defaultImg from "../../common/assets/img/default.jpeg";
import { capitalizeFirstLetter, numberToCurrency } from "../../utils";
import UserAvatar from "../../components/user-avatar/UserAvatar";
import NumberComponent from "../../components/number-component/NumberComponent";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";


const SingleAdvert = () => {
  register();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activePhoto, setActivePhoto] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [validUserToken, setValidUserToken] = useState(false);




  const api = useApi();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("dem_user"));
    const tokens = JSON.parse(localStorage.getItem("dem_tokens"));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
    const currentTime = Date.now() / 1000;
    const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    setValidUserToken(isValidAccessToken);
    if (tokens && tokens.access && isValidAccessToken) {
      setValidUserToken(!!user);
      setUser(user);
    }

    api
      .getAdvert(id)
      .then((response) => {
        setData(response);
        setActivePhoto(response?.photos[0])
        setLoading(false);
        console.log("respnse : ", response);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return  <ContentWrapper>
      <Header />
      <Loader />;
    </ContentWrapper>
    
  }

  if (error) {
    return (
        <ContentWrapper>
      <Header />
      
      <h2 style={{ textAlign: "center", paddingBlock: "2rem" }}>
        {" "} 
        {error?.response?.status === 404
          ? "Impossible de trouver l'annonce recherchée !"
          : "Une erreur s'est produite, veuillez réessayer plutard !"+JSON.stringify(error)}
      </h2>
    </ContentWrapper>
    );
  }
  console.log("data : ",data)

  const deleteAdvert = () => {  
    const tokens = JSON.parse(localStorage.getItem("dem_tokens"));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
    const currentTime = Date.now() / 1000;
    const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    if(isValidAccessToken){
      api.deleteAdvert(id)
        .then((response) => {
          console.log("response of delete : ",response);
          navigate("/adverts")
          Swal.fire("Félicitation","Annonce supprimée avec succès","success")
        })
        .catch((error) => {
          console.log("error",error)
          Swal.fire("Oops","Une erreur s'est produite lors de la suppression de cette annonce","error")
        });
    }else{
      window.alert("Action impossible !");
    }
  }

  return (
    <ContentWrapper>
      <Header />
      <div className="advert-details" style={{ position:"relative" }}>
      {user && user.id.toString() === data.id_user.toString() && 
        <div style={{ position:"absolute" ,top:"-20px",right:"0", display:"flex", gap:"1rem"}}>
          <Link to={`/adverts/${data.id}/update`} title="Modifier" style={{ all:"unset", cursor:"pointer" ,borderRadius:"50%",padding:"10px 15px",background:"blue",color:"white" }}><i className="fa-solid fa-pen-to-square"></i></Link>
          <button onClick={deleteAdvert} title="Supprimer" style={{ all:"unset", cursor:"pointer" ,borderRadius:"50%",padding:"10px 15px",background:"red",color:"white" }}><i className="fa-solid fa-trash"></i></button>
        </div>
      }
        <div className="left-content">
        <p style={{paddingBottom:'10px',display:"flex",justifyContent:"space-between",alignItems:"center" }}>
        <span><strong className="" style={{ fontSize:"20px" }}>{capitalizeFirstLetter(data.type_bien)} à {data.types_offre}</strong>(Lomé)</span>
        <span className="" style={{ fontSize:"12px" }}>Postée il y'a {dayjs(data.date_created).fromNow()}</span>
        </p>
        <img src={activePhoto??defaultImg} style={{ position:"relative",width:"100%",height:"400px",objectFit:"cover"}}/>
        
        <div style={{ display:"flex",gap:"1rem",margin:"1rem",overflowX:"scroll" }}>

        {
           data.photos.length>0 && data.photos.map((photo, index)=><img onClick={()=>{setActivePhoto(photo)}} key={index}  src={photo} style={{ position:"relative",width:"60px",height:"60px",objectFit:"cover",borderRadius:"5px",cursor:"pointer",border:"1px solid #eaeaea",filter: activePhoto!==photo? "grayscale(1)":"none"}}/>)
        }

        </div>
        <br/>
        <div>
        <strong>Prix/Budget</strong> <br/>
        {numberToCurrency(data.budget)} Fcfa</div>
        <br/>
        <div>
        <strong>Description</strong> <br/>
        {data.description}</div>
        </div>
        
        <div className="right-content">
        <UserAvatar user={data.user}/>
        {data.user?.typeUser !== "demarcheur" &&
      ["demmande", "demande"].includes(data.types) ? (
        <div style={{ display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" }}>

        <strong style={{ textAlign:"center",margin:"1.5rem",color:"red" }}>Mode ***Démarcheur*** requis pour voir les contact de cette annonce</strong>

        <Link target="_blank" to="https://wa.me/22897205337?text=Bonjour, Je voudrais faire passer mon compte au profil Démarcheur. Merci">
            <DefaultButton>Devenez démarcheur</DefaultButton>
          </Link>
        </div>
      ) : (
        <NumberComponent number={data.user.tel} advert={data}/>
        
      )}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default SingleAdvert;

import React from 'react'
import Header from '../../components/header/header'
import Hero from '../../components/hero/Hero'
import Adverts from '../../components/advert/Adverts'
import Footer from '../../components/footer/Footer'
import PublishNow from '../../components/PublishNow/PublishNow'

function Home() {
  return (
    <>
        <Header/>
        <Hero/>
        <Adverts limit={8}/>
        <Footer/>
      
      <PublishNow/>
    </>
  )
}

export default Home
import React from 'react';
import AuthComponent from '../../../components/auth/AuthComponent';
import RegisterForm from './RegisterForm';

const registerComponent = {
  title: 'Nouveau compte',
  subtitle: 'Débutez avec une inscription',
  anotherLinkLabel: 'Se connecter',
  anotherLinkPath: '/login',
  description: 'Trouvez votre prochain logement de rêve grâce à Allô Démarcheur, la plateforme en ligne pour la location d\'appartement et de chambres.',
};

function Register() {
  const handleRegisterFormSubmit = (data) => {
    console.log('Register form data:', data);
  };

  return (
    <AuthComponent {...registerComponent} form={<RegisterForm onSubmit={handleRegisterFormSubmit} />} />
  );
}

export default Register;

import React, { useState, useEffect } from 'react';
import AdvertCard from './advert-card/AdvertCard';
import useApi from '../../services/api';
import Loader from '../Loader/Loader';

function AdvertList({ updateLoadingState, limit, filters, updateTotalElements }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    console.log("filters in list : ",filters);
    api.getAdverts(filters??{})
      .then((response) => {
        const advertList = response;
        const limitedData = limit ? advertList.slice(0, limit) : advertList;
        setData(limitedData);
        setLoading(false);
        updateLoadingState(false);
        updateTotalElements && updateTotalElements(advertList.length);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
        updateLoadingState(false);
      });
  }, [filters]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="announces-container">
      {data.map((advertData, index) => (
        <AdvertCard key={index} data={advertData} />
      ))}
    </div>
  );
}

export default AdvertList;

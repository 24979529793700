import React from 'react'
import "./Loader.css"

function Loader(props) {
  return (
    <div className='spinner-container'>
        <div className="spinner"></div>
        <div className="loading-text">{props.children??'Chargement en cours ...'}</div>
    </div>
  )
}

export default Loader
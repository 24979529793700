import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Loader from './components/Loader/Loader';

const PrivateOutlet = () => {
  const [authenticated, setAuthenticated] = useState(null); // Use 'null' to represent the initial loading state

  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('dem_tokens'));
    // Perform the asynchronous check
    if (tokens) {
      const decodedAccessToken = jwtDecode(tokens.access);
      const currentTime = Date.now() / 1000;
      const isValidAccessToken = decodedAccessToken.exp > currentTime;
      
      if (tokens && tokens.access && isValidAccessToken) {
        // Faire une autre requete pour recuperer les informations de l'utilisateur avant de renvoyer.
        setAuthenticated(true);
        // Plutard verifier . si le access token est invalide, checker le refresh token hstoire de faire une requete de refresh token
      } else {
        setAuthenticated(false);
      }
    } else {
      setAuthenticated(false);
    }
  }, []);

  // Render a loading state if 'authenticated' is null
  if (authenticated === null) {
    return <div className="main-loader">
    <Loader>Veuillez patienter svp ...</Loader>
  </div>;
  }

  // Render the component based on the 'authenticated' state
  return authenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateOutlet;

import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import PublishNow from "../../components/PublishNow/PublishNow";
import Footer from "../../components/footer/Footer";
import AdvertList from "../../components/advert/AdvertList";
import ContentWrapper from "../../components/content-wrapper/ContentWrapper";
import "./Adverts.css";
import AdvertFilters from "../../components/advert-filters/AdvertFilters";
import UserAvatar from "../../components/user-avatar/UserAvatar";
import HorizUsers from "../../components/horiz-users/HorizUsers";

function Adverts() {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const updateCheckedState = () => {
    const isMobile = window.innerWidth < 768;
    setCollapsed(isMobile);
  };
  const updateTotalElements = (total) => {
    setTotalElements(total);
  };

  const updateLoadingState = (isLoading) => {
    setLoading(isLoading);
  };

  const   updateFiltersState = (filters) => {
    setFilters(filters);
  };

  const toggleFilter = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    updateCheckedState();
    window.addEventListener('resize', updateCheckedState);
    return () => window.removeEventListener('resize', updateCheckedState);
  }, []);

  return (
    <ContentWrapper>
      <Header updateFiltersState={updateFiltersState} />
      <div className={`adverts-all ${collapsed ? "collapsed" : ""}`}>
        {/* <HorizUsers/> */}
        <div className="filters-adverts">
          <div className="filters-box">
            <label htmlFor="filterToggle" className="filter-toggle-icon">
              <input
                type="checkbox"
                name="filterToggle"
                id="filterToggle"
                onChange={toggleFilter}
                checked={!collapsed}
              />
              <span className="toggle-icon"><i className="fas fa-arrow-circle-right fa-lg"></i></span>
            </label>
            <aside className="filters-sidebar">
              <AdvertFilters updateFiltersState={updateFiltersState} />
            </aside>
          </div>
          <div className={`adverts-box ${loading ? 'loading' : ''}`}>
            <h5 className="announce-list-title">Liste des annonces ({totalElements})</h5>
            <AdvertList updateLoadingState={updateLoadingState} filters={filters} updateTotalElements={updateTotalElements}/>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <PublishNow />
    </ContentWrapper>
  );
}

export default Adverts;

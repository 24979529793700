import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  motifs,
  motifService,
  typeBiens,
  villesTogo,
} from "../../common/data/data";
import useApi from "../../services/api";
import { useDropzone } from "react-dropzone";
import jwtDecode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Swal from "sweetalert2";

function  NewAdvertForm({isUpdate=false}) {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue  
  } = useForm();
  const demandType = watch("types");
  const api = useApi();
  const navigate = useNavigate();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validUserToken, setValidUserToken] = useState(false);
  const [user, setUser] = useState(null);
  const [advertData, setAdvertData] = useState(null);
  const [loading, setLoading] = useState(isUpdate || false);
  const [error, setError] = useState(null);



  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("dem_user"));
    const tokens = JSON.parse(localStorage.getItem("dem_tokens"));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
    const currentTime = Date.now() / 1000;
    const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    if (tokens && tokens.access && isValidAccessToken) {
      setValidUserToken(!!user);
      setUser(user);


      // recuperer les details de l'annonce
      if (isUpdate) {
        api
          .getAdvert(id)
          .then((response) => {
            
            if(response.id_user.toString() !== user.id.toString()){
              navigate(`/adverts/${id}`);
            }

            setAdvertData(response);
            // Pré-remplir le formulaire avec les données de l'annonce récupérées
            Object.keys(response).forEach((key) => {
              setValue(key, response[key]);
            });
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }
    }
  }, [id, isUpdate, setValue]);

  const splitFiles = (data) => {
    const newData = { ...data };
    if (acceptedFiles && acceptedFiles.length > 0) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        newData[`photo${i + 1}`] = acceptedFiles[i];
      }
    }
    return newData;
  };
  // const user = {
  //   id_user : 32,
  //   typeUser : "demarcheur"
  // }
  const onSubmit = async (data) => {
    if (validUserToken) {
      data.id_user = user.id;
      const formattedData = splitFiles(data);
      let message;
      let result;
      try {
        if (isUpdate) {
          result = await api.updateAdvert(id, formattedData);
          message = "Annonce mise à jour avec succès !"
        } else {
          result = await api.createAdvert(formattedData);
          message = "Annonce publiée avec succès !"
        }
        console.log("result : ",result)
        reset();
        setAcceptedFiles([]);
        setImagePreviews([]);
        navigate(`/adverts/${result.data.id}`)
        Swal.fire("Félicitation",message,"success")
      } catch (error) {
        setErrorMessage("Une erreur s'est produite lors de la publication de l'annonce.");
        Swal.fire("Oops","Une erreur s'est produite lors de la publication de l'annonce.","error")
      }
    } else {
      navigate("/login");
    }
  };

  const handleFileChange = (acceptedFiles) => {
    setAcceptedFiles(acceptedFiles);
    const filePreviews = acceptedFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews(filePreviews);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 5,
    onDrop: handleFileChange,
  });

  const handleRemoveImage = (index) => {
    const updatedAcceptedFiles = acceptedFiles.filter((file, i) => i !== index);
    setAcceptedFiles(updatedAcceptedFiles);
    const filePreviews = updatedAcceptedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    setImagePreviews(filePreviews);
  };

  if (loading) {
    return   <Loader />;
    
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="new-announce-form">
      {successMessage && (
        <span className="success-message top">{successMessage}</span>
      )}
      {errorMessage && (
        <span className="error-message top">{errorMessage}</span>
      )}
      <div className="form-group">
        <label htmlFor="demand-type-select">Type de demande *</label>
        <select
          id="demand-type-select"
          className="input-field"
          {...register("types", { required: true })}
        >
          {user?.typeUser === "demarcheur" ? (
            <>
              <option value="">Sélectionnez un type de demande</option>
              {motifs.map((motif) => (
                <option key={motif.value} value={motif.value}>
                  {motif.title}
                </option>
              ))}
            </>
          ) : (
            <option value="demande" selected={true} disabled>
              Demande
            </option>
          )}
        </select>
        {errors.demandType && (
          <span className="error-message">Ce champ est requis</span>
        )}
      </div>
      {(demandType === "demande" || demandType === "offre") && (
        <div className="form-group">
          <label htmlFor="service-type-select">Service *</label>
          <select
            id="service-type-select"
            className="input-field"
            {...register("types_offre", { required: true })}
          >
            <option value="">Sélectionnez un service</option>
            {motifService
              .filter((service) => {
                if (demandType === "demande") {
                  return (
                    service.value === "louer" || service.value === "acheter"
                  );
                }
                if (demandType === "offre") {
                  return (
                    service.value === "louer" || service.value === "vendre"
                  );
                }
                return false;
              })
              .map((service) => (
                <option key={service.value} value={service.value}>
                  {service.title}
                </option>
              ))}
          </select>
          {errors.serviceType && (
            <span className="error-message">Ce champ est requis</span>
          )}
        </div>
      )}
      <div className="form-group">
        <label htmlFor="property-type-select">Type de bien recherché *</label>
        <select
          id="property-type-select"
          className="input-field"
          {...register("type_bien", { required: true })}
        >
          <option value="">Sélectionnez un type de bien</option>
          {typeBiens.map((category) => (
            <option key={category.value} value={category.value}>
              {category.title}
            </option>
          ))}
        </select>
        {errors.propertyType && (
          <span className="error-message">Ce champ est requis</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="budget-input">Budget *</label>
        <input
          type="number"
          id="budget-input"
          className="input-field"
          {...register("budget", { required: true })}
        />
        {errors.budget && (
          <span className="error-message">Ce champ est requis</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="city-select">Ville *</label>
        <select
          id="city-select"
          className="input-field"
          {...register("ville", { required: true })}
        >
          <option value="">Sélectionnez une ville</option>
          {villesTogo.sort().map((ville, index) => (
            <option key={index} value={ville}>
              {ville}
            </option>
          ))}
          <option value="Autre">Autre</option>
        </select>
        {errors.city && (
          <span className="error-message">Ce champ est requis</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="neighborhood-input">Quartier</label>
        <input
          type="text"
          id="neighborhood-input"
          className="input-field"
          {...register("quartier", { required: true })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description-textarea">Description</label>
        <textarea
          id="description-textarea"
          className="input-field"
          {...register("description")}
        ></textarea>
      </div>
      {demandType === "offre" && (
        <div className="form-group">
          <label htmlFor="files-input">
            Télécharger des fichiers (maximum de 5 fichiers)
          </label>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p className="dropZone">
              Glissez et déposez des fichiers ici, ou cliquez pour sélectionner
              des fichiers
            </p>
          </div>
        </div>
      )}
      <div className="image-previews">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="image-preview">
            <img
              src={preview}
              alt={`Preview ${index}`}
              className="preview-image"
            />
            <button
              type="button"
              className="remove-button"
              onClick={() => handleRemoveImage(index)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        ))}
      </div>
      <button className="submit-button" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (isUpdate?"Mise à jour en cours...":"Publication en cours...") : (isUpdate? "Mettre à jour" : " Publier l'annonce")}
      </button>
    </form>
  );
}

export default NewAdvertForm;

import React from "react";
import Header from "../../components/header/header";
import Hero from "../../components/hero/Hero";
import Adverts from "../../components/advert/Adverts";
import Footer from "../../components/footer/Footer";
import PublishNow from "../../components/PublishNow/PublishNow";
import ContentWrapper from "../../components/content-wrapper/ContentWrapper";
import dash1 from "../../common/assets/img/dash1.png";
import dash2 from "../../common/assets/img/dash2.png";
import AdvertList from "../../components/advert/AdvertList";

function ProfilData() {
    const filters = {id_user:4}
  return (
    <>
      <ContentWrapper>
        <Header />
        <div style={{ marginInline:"2rem" }}>
        <h1 className="section-title">Mes annonces</h1>
        <p style={{ textAlign:"center",marginBottom:"1rem" }}>Gérez vos annonces ici !</p>
        <AdvertList updateLoadingState={()=>{}} filters={filters} />

        </div>
        {/* <Footer /> */}
      </ContentWrapper>
    </>
  );
}

export default ProfilData;

const motif = {
    SEARCHING : "Je cherche",
    LENDING : "Je loue",
    SELLING : "Je vend"
}

const type = {
    HOUSE : "Maison",
    APARTMENT : "Appartement",
    ROOM : "Pièce",
    INTERNAL_ROOM : "Pièce WCD Interne",
    LV: "Ch. Salon",
    LVI: "Ch. Salon Interne",
    LAND : "Terrain",
}

export const typeBiens = [
    { title: "Piece Simple", value: "piece simple" },
    { title: "Piece Interne", value: "piece interne" },
    { title: "Chambre/Salon Simple", value: "chambresalon simple" },
    { title: "Chambre/Salon Interne", value: "chambresalon interne" },
    { title: "Villa", value: "villa" },
    { title: "Terrain", value: "terrain" },
    { title: "Appartement Meuble", value: "appartement meuble" },
    { title: "Studio", value: "studio" }
  ];


  export const motifs = [
    {
        title:"Demande", 
        value:"demande"
    },
    {
        title:"Offre", 
        value:"offre"
    },
  ];

  export const motifService = [
    {
        title:"Louer", 
        value:"louer"
    },
    {
        title:"Acheter", 
        value:"acheter"
    },
    {
        title:"Vendre", 
        value:"vendre"
    }
  ];

  export const villesTogo = [
    'Lomé',
    'Sokodé',
    'Kara',
    'Atakpamé',
    'Tsévié',
    'Kpalimé',
    'Bassar',
    'Aného',
    'Notsé',
    'Aneho',
    'Tabligbo',
    'Vogan', 
    'Dapaong',
    'Mango',
    'Afagnan',
    'Pagouda',
    'Kpeme',
    'Amlame',
    'Sansanne-Mango',
    'Bafilo',
    'Badou',
    'Blitta',
    'Boulogne',
    'Cinkasse',
    'Tandjouare'
  ];

const adverts = [
    {
        date: new Date(),
        motif:motif.SEARCHING,
        
    }
]
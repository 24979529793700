// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/auth/login/Login";
import Register from "./pages/auth/register/Register";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import Adverts from "./pages/adverts/Adverts";
import NewAdvert from "./pages/new-advert/NewAdvert";
import { Fragment } from "react";
import PrivateOutlet from "./PrivateOutlet";
import Dashboard from "./pages/dashboard/Dashboard";
import SingleAdvert from "./pages/single-advert/SingleAdvert";
import ProfilData from "./pages/profil/ProfilData";

function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/adverts" element={<Adverts />} />
          <Route path="/adverts/:id" element={<SingleAdvert />} />
          <Route
            path="/adverts/:id/update"
            element={<PrivateOutlet />}
          >
            <Route
              path="/adverts/:id/update"
              element={<NewAdvert isUpdate={true} />}
            />
          </Route>
          <Route exact path="/new-advert" element={<PrivateOutlet />}>
            <Route exact path="/new-advert" element={<NewAdvert />} />
          </Route>
          <Route exact path="/dashboard" element={<PrivateOutlet />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route exact path="/profil" element={<PrivateOutlet />}>
            <Route exact path="/profil" element={<ProfilData />} />
          </Route>
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;

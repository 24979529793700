import React from "react";
import NewAdvertForm from "./NewAdvertForm";
import ContentWrapper from "../../components/content-wrapper/ContentWrapper";
import Header from "../../components/header/header";
import Footer from "../../components/footer/Footer";
import "./NewAdvert.css";
function NewAdvert({isUpdate}) {
  return (
    <ContentWrapper>
      <Header />
      <div className="new-announce-card">
      <h1 className="new-announce-card-title"><i class="fas fa-bullhorn"></i>{isUpdate?"Mise à jour d'annonce":"Nouvelle Annonce"}</h1>
        <NewAdvertForm isUpdate={isUpdate} />
      </div>
      <Footer />
      {/* <PublishNow /> */}
    </ContentWrapper>
  );
}

export default NewAdvert;

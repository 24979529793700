import React from 'react'
import "./Footer.css"
function Footer() {
  return (
    <footer >
    <div className="footer-container">
      <small>Copyright 2023 &copy; Allô Démarcheur. Tous droits reservés.</small>
    </div>
  </footer>
  )
}

export default Footer
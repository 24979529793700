import React from "react";
import "./AuthComponent.css";
import DarkMode from "../DarkMode/DarkMode";
import DefaultButton from "../DefaultButton/DefaultButton";
import { Link } from "react-router-dom";

function AuthComponent({ title, subtitle, anotherLinkLabel, anotherLinkPath, description, form }) {
  return (
    <div className="auth-wrapper">
      <div className="top-section">
        <div className="logo"></div>
        <DarkMode />
      </div>
      <div className="bottom-section">
        <div className="left">
          <div className="top">
            <h1 className="title">{title}</h1>
            <h4 className="subtitle">
              {subtitle} ou &nbsp;
              <Link className="another-link" to={anotherLinkPath}>
                {anotherLinkLabel}
              </Link>
            </h4>
          </div>

          <div className="middle">
            <hr className="top-bar"/>
            <div className="content">
              {description}
            </div>
          </div>

          <div className="bottom">
            <DefaultButton><Link to="/">Retournez à l'accueil</Link></DefaultButton>
          </div>
        </div>

        <div className="right">
        <h4 className="subtitle">
              {subtitle} ou &nbsp;
              <Link className="another-link" to={anotherLinkPath}>
                {anotherLinkLabel}
              </Link>
            </h4>
            <div className="auth-form-container scrollable-content">
                <h2 className="form-title">{title}</h2>
                {form}
            </div>
            <div className="footer-btn">
            <DefaultButton><Link to="/">Retournez à l'accueil</Link></DefaultButton>
            </div>
        </div>
      </div>
    </div>
  );
}

export default AuthComponent;

// LoginForm.js
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useApi from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

function LoginForm() {
  const { register, handleSubmit, formState: { isSubmitting, errors }, setError, clearErrors, reset } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const registrationSuccess = location?.state?.registrationSuccess;

  const handleFormSubmit = async (data) => {
    try {
      setLoginError(null);
      const response = await api.login(data);
      const tokens = response.data;
      const decodedAccessToken = jwtDecode(tokens.access);
      localStorage.setItem('dem_tokens', JSON.stringify(tokens));
      const user = (await api.getUser(decodedAccessToken.user_id)).data;
      localStorage.setItem('dem_user', JSON.stringify(user));
      navigate("/");
    } catch (error) {
      let msg = "Désolé, une erreur s'est produite !";
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        msg = "Identifiant ou mot de passe incorrecte.";
        setError("password", { type: "manual", message: msg });
      }
      setLoginError(msg);
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => handleFormSubmit(data).finally(() => reset()))}>
      {loginError && <span className="error-message top">{loginError}</span>}
      {registrationSuccess && (
        <span className="success-message top">
          Enregistrement réussi ! Connectez-vous avec vos identifiants.
        </span>
      )}
      <div className="form-group">
        <label htmlFor="username-input">Nom d'utilisateur / Numéro de téléphone</label>
        <input
          type="text"
          id="username-input"
          className="input-field"
          placeholder="User1234 ou 98 XX XX XX"
          {...register('username', { required: true })}
        />
        {errors.username && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group login-password-group">
        <label htmlFor="password-input">Mot de passe</label>
        <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password-input"
            className="input-field"
            placeholder="********"
            {...register('password', { required: true })}
          />
          {errors.password && <span className="error-message">{errors.password.message}</span>}
          <i
            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} password-toggle-icon`}
            onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
          ></i>
        </div>
      </div>
      <div className="forgot-password" style={{ float: 'right' }}>
        <a href="#!" onClick={() => console.log('forgot password clicked')}>
          Mot de passe oublié ?
        </a>
      </div>
      <button className="submit-button" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Connexion en cours...' : 'Se connecter'}
      </button>
    </form>
  );
}

export default LoginForm;

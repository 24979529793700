import React, { useEffect, useState } from "react";
import "./AdvertCard.css";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import Modal from "../../Modal/Modal";
import { register } from "swiper/element/bundle";
import useApi from "../../../services/api";
import jwtDecode from "jwt-decode";
import defaultImg from "../../../common/assets/img/default.jpeg";
import NumberComponent from "../../number-component/NumberComponent";
import { numberToCurrency } from "../../../utils";

dayjs.extend(relativeTime);
dayjs.locale("fr");

function AdvertCard(data) {
  const api = useApi();
  register();
  const {
    description,
    date_created,
    budget,
    quartier,
    ville,
    type_bien,
    id_user,
    photos,
    types,
    types_offre,
  } = data.data;
  const annonceDate = new Date(date_created);
  const [user, setUser] = useState(null);
  useEffect(() => {
    // Check if the user is logged in (you should replace this with the actual logic)
    const user = JSON.parse(localStorage.getItem("dem_user"));
    const tokens = JSON.parse(localStorage.getItem("dem_tokens"));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
    const currentTime = Date.now() / 1000;
    const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    if (tokens && tokens.access && isValidAccessToken) {
      setUser(user);
    }
  }, []);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [advertAuthor, setAdvertAuthor] = useState(null);
  // const defaultImg =
  //   "https://www.baticopro.com/media/post/image/resizedPostView/istock-949087660.jpg";

  useEffect(() => {
    // Fonction pour récupérer les informations de l'utilisateur
    const fetchUserData = async () => {
      try {
        const user = (await api.getUser(id_user)).data;
        setAdvertAuthor(user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (isContactModalOpen && id_user) {
      fetchUserData();
    }
  }, [isContactModalOpen, id_user]);
  return (
    <div className="announce-card">
      <div className="top-section">
        <div className="overlay">
          <div className="author-info">
            <div className="avatar"></div>
            <span className="date">{dayjs(annonceDate).fromNow()}</span>
            <span className="motif">
              {types === "offre" ? "À" : "Je cherche à"} {types_offre}
            </span>
          </div>
          <div className="like-info">
            {/* <i className="eye-icon fas fa-eye"></i>
            <span className="views">123</span> */}
          </div>
        </div>
        <div
          className="image-container"
          onClick={() => {
            console.log("phots : ", photos, photos.length);
            photos.length > 0 && setIsImagesModalOpen(true);
          }}
        >
          <img src={photos.length > 0 ? photos[0] : defaultImg} alt="Advert" />
          {/* <img src="https://img.freepik.com/photos-gratuite/maison-rouge-forme-cle-dans-serrure_1156-629.jpg?w=900&t=st=1687634025~exp=1687634625~hmac=e83dca7da9c33c7d4f843b07062125331fa5f15e667f917d3c4f23f3afb704a0" alt="Advert" /> */}
        </div>
        {isImagesModalOpen && (
          <Modal label="Apreçu des images" closeModal={setIsImagesModalOpen}>
            <small className="info-msg">
              NB : Les images ci dessous peuvent ne pas refleter la realité.
              Exiger de vous rendre sur les lieux.
            </small>
            <hr />
            <swiper-container
              class="mySwiper"
              thumbs-swiper=".mySwiper2"
              space-between="10"
              navigation="true"
            >
              {photos.map((link, index) => (
                <swiper-slide key={index}>
                  <img src={link} alt={`Img ${index}`} />
                </swiper-slide>
              ))}
            </swiper-container>

            <swiper-container
              class="mySwiper2"
              space-between="10"
              slides-per-view="4"
              free-mode="true"
              watch-slides-progress="true"
            >
              {photos.map((link, index) => (
                <swiper-slide key={index}>
                  <img src={link} alt={`Img ${index}`} />
                </swiper-slide>
              ))}
            </swiper-container>
          </Modal>
        )}
      </div>
      <div className="bottom-section">
        <div className="column-container">
          {/* <div className="column">
            <i className="motif-icon fas fa-tag"></i>
            <span className="value">Vente</span>
          </div> */}
          {/* <div className="column">
            <i className="type-icon fas fa-home"></i>
            <span className="value">{type_bien}</span>
          </div> */}
          <div className="column">
            <i className="location-icon fas fa-map-marker-alt"></i>
            <span className="value">
              {quartier}, {ville}
            </span>
          </div>
          <div className="column">
            {/* <i className="budget-icon fas fa-euro-sign"></i> */}
            <i class="fa-solid fa-coins"></i>
            <span className="value">{numberToCurrency(budget)} Fcfa</span>
          </div>
        </div>

        <div className="card-links">
          <h3 className="title">
            <a href={`/adverts/${data.data.id}`} style={{ color:"#0EAEFA" }}>{type_bien}</a>
          </h3>
        </div>
        <span className="description">{description}</span>
        {/* <span className="description">J'ai besoin d'une pièce propre et bien espacée (Menages max : 4)</span> */}
      </div>
      {/* Ce bouton permettra d'afficher un modal avec le nom de la boite ainsi que des details concernant l'entreprise */}
      {user?.typeUser !== "demarcheur" &&
      ["demmande", "demande"].includes(types) ? (
        <></>
      ) : (
        <div
          className="contact-links"
          onClick={() => {
            setIsContactModalOpen(true);
          }}
        >
          Contactez-nous
        </div>
      )}
      {isContactModalOpen && (
        <Modal
          label="Contactez-nous"
          closeModal={() => setIsContactModalOpen(false)}
        >
          {advertAuthor ? (
            <div>
              <small className="info-msg">
                NB : Les informations ci-dessous peuvent ne pas être avérées.
              </small>
              <hr />
              {/* <div>Numero de téléphone : {advertAuthor.tel}</div> */}
              <NumberComponent number={advertAuthor.tel} advert={data.data} />
              {/* {JSON.stringify(advertAuthor)} */}
            </div>
          ) : (
            <div>Chargement...</div>
          )}
        </Modal>
      )}
    </div>
  );
}

export default AdvertCard;

import React from "react";
import { getDomainFromURL } from "../../utils";

const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replaceAll(/\s+/g, "").replace(/[-()]/g, "");
};

const NumberComponent = ({ number,advert }) => {
  const phone = cleanPhoneNumber(number);
  
  return (
    <div className="number-buttons">
      <a className="number-button" href={`tel:${phone}`}>
      <i class="fa-solid fa-phone"></i>
        Appeler directerement
      </a>
      <a
        className="number-button whatsapp"
      
        // Ou utiliser un wa.me/number
        href={`https://wa.me/${phone}?text=Votre annonce publiée sur Allô Demarcheur m'intéresse. https://${getDomainFromURL()}/adverts/${advert.id}`}
        target="_blank"
        rel="noreferrer"
      >
      <i class="fa-brands fa-whatsapp"></i>
        Contacter via WhatsApp
      </a>
    </div>
  );
};

export default NumberComponent;

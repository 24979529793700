import React from 'react'
import DefaultButton from '../DefaultButton/DefaultButton'
import './PublishNow.css'
import { Link } from 'react-router-dom'

function PublishNow() {
  return (
    <div className="fab">
      <Link to="/new-advert"><DefaultButton><i className="fas fa-bullhorn"></i>&nbsp; Publiez une annonce</DefaultButton></Link>  
    </div>
  )
}

export default PublishNow
import axios from "axios";
import { calculerDatePrecedente } from "../utils";

const useApi = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const V2_BASE_URL = process.env.REACT_APP_BASE_URL_V2;
  const TEST_BASE_URL = process.env.REACT_APP_TEST_URL;
  const DEMA_TEST_BASE_URL = process.env.REACT_APP_DEMA_TEST_URL;

  const USER_TOKENS = JSON.parse(localStorage.getItem("dem_tokens"));
  const USER_DATA = JSON.parse(localStorage.getItem("dem_user"));

  const api = axios.create({ baseURL: BASE_URL });
  const v2_api = axios.create({ baseURL: V2_BASE_URL });
  const test_api = axios.create({ baseURL: TEST_BASE_URL });
  const dema_test_api = axios.create({ baseURL: DEMA_TEST_BASE_URL });

  const handleRequest = (instance, authenticated = false) => {
    if (authenticated) {
      // Inclure le token d'accès Bearer dans les en-têtes
      instance.defaults.headers.common["Authorization"] = `Bearer ${USER_TOKENS?.access}`;
    }
    return instance;
  };

  const login = async (data) => {
    return handleRequest(dema_test_api, false).post("/token/", data);
  };

  const register = async (data) => {
    return handleRequest(dema_test_api).post("/account/", data);
  };

  const getUser = async (id) =>{
    return handleRequest(dema_test_api).get(`/account/${id}`);
  }

  const getAdverts = async (filters) => {
    // const params = Object.entries(filters)
    //             .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    //             .join("&");
    // const queryUrl = ;
    // console.log('params : ',params)
    // console.log('query url : ',queryUrl)
    console.log("filters are   //: ",filters)
    let queries = {};
    if(filters?.priceRange){
      queries.budget__lte = filters?.priceRange?.max;
      queries.budget__gte = filters?.priceRange?.min;
    }
    if(filters?.city && filters?.city!=="all" ){
      queries.ville = filters.city;
    }

    if(filters?.description && filters?.description !== ""){
      queries.description__icontains = filters?.description
    }

    if(filters?.category && filters?.category !== "all"){
      queries.type_bien = filters.category;
    }

    if(filters?.tag && filters?.tag !== "all"){
      if(filters?.tag === "searching"){
        queries.types = "demande";
      }

      if(filters?.tag === "selling"){
        queries.types = "offre";
        queries.types_offre = "vendre";
        
      }

      if(filters?.tag === "lending"){
        queries.types = "offre";
        queries.types_offre = "louer";
        
      }
    }

    if(filters?.mineOnly){
      queries.id_user = USER_DATA.id;
    }

    queries.date_created_lte = calculerDatePrecedente(filters.postedSince);

    console.log("final query : ",queries)
    const queryString = Object.entries(queries)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
    
    try {
      const advertsResponse = await handleRequest(dema_test_api).get(`/annonces?${queryString}`);
      const adverts = advertsResponse.data.results;
      const updatedAdverts = await Promise.all(
        adverts.map(async (advert) => {
          try {
            // const userResponse = await handleRequest(test_api).get(`/account/${advert.id_user}`);
            // const user = userResponse.data;
            const photos = [advert.photo1, advert.photo2, advert.photo3, advert.photo4, advert.photo5].filter(photo => photo !== null);
    
            return {
              ...advert,
              // user: user,
              photos: photos
            };
          } catch (error) {
            console.error("Error fetching user data:", error);
            // Return an empty object or a placeholder data if user data cannot be fetched
            return {
              ...advert,
              // user: {},
              photos: []
            };
          }
        })
      );
      console.log("adverts updated final list : ",updatedAdverts);
      return updatedAdverts;
    } catch (error) {
      console.error("Error fetching adverts:", error);
      throw error;
    }
  };

  const getAdvert = async (id) =>{
    try {
      const advertResponse = await handleRequest(dema_test_api).get(`/annonce/${id}`);
      const data = advertResponse.data;
      const photos = [data.photo1, data.photo2, data.photo3, data.photo4, data.photo5].filter(photo => photo !== null);
      delete data.photo1;delete data.photo2;delete data.photo3;delete data.photo4;delete data.photo5;
      const user = (await getUser(advertResponse.data.id_user)).data;
      return {...data,photos,user:{first_name:user.first_name,last_name:user.last_name,tel:user.tel,email:user.email,profile:user.profile_photo}};
    } catch (error) {
      console.error("Error fetching adverts:", error);
      throw error;
    }
  }
  

  const createAdvert = async (data) =>{
    const instance = handleRequest(dema_test_api);
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    console.log("formdata : ",formData);
    formData.append("nombre_menage",0);
    return instance.post("/annonce/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  const updateAdvert = async (id, data) => {
    const instance = handleRequest(dema_test_api);
    const formData = new FormData();
  
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append("nombre_menage",0);
    return instance.put(`/annonce/${id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const deleteAdvert = async (id) => {
    const instance = handleRequest(dema_test_api);
    try {
      const response = await instance.delete(`/annonce/${id}/`);
      return response.data;
    } catch (error) {
      console.error("Error deleting advert:", error);
      throw error;
    }
  };
  

  return {
    login,
    register,
    getUser,
    getAdverts,
    createAdvert,
    updateAdvert,
    getAdvert,
    deleteAdvert
  };
};

export default useApi;

import React from "react";
import "./Modal.css";
import { useState } from "react";

function Modal({
  label,
  children,
  closeModal,
  showActionButtons,
  actionButtonLabel,
  actionButton2Label,
  actionButtonIcon,
  actionButton2Icon,
  onActionButtonClick,
  onActionButton2Click,
  actionButtonsClass,
  headerClassName,
  closeBtnSecondaryStyle
}) {

  const [lg,setLg] = useState(false);
  const lgStyle = {width:"85%"};
  return (
    <div className="modal-bg">
      <div className="modal-container" style={lg?lgStyle:null}>
        <div className="modal-header">
          <span className={`${headerClassName}`}>{label}</span>
          <button className="modal-setting-btn" onClick={()=>setLg(!lg)}>{lg?'Réduire':'Elargir'}</button>
        </div>
        <div className="modal-content">
        <div id="camunda-form-container"></div>
        {children}
        </div>
        <div className="modal-footer">
          <div className="buttons-container">
            <button className={`${closeBtnSecondaryStyle?'modal-setting-btn':'modal-inline-button'}`} onClick={() => closeModal(false)}>Fermer</button>
            {showActionButtons && (
              <>
              {
                (actionButtonIcon || actionButtonLabel) && <button
                onClick={onActionButtonClick}
                className={`cta ${actionButtonsClass}`}
              >
              <i className={`${actionButtonIcon}`}></i>
                {actionButtonLabel}
              </button>
              }
              {
                (actionButton2Icon || actionButton2Label) && <button
                onClick={onActionButton2Click}
                className={`cta ${actionButtonsClass}`}
              >
              <i className={`${actionButton2Icon}`}></i>
                {actionButton2Label}
              </button>
              }
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;

import React, { useEffect, useState } from 'react';
import './header.css';
import DarkMode from '../DarkMode/DarkMode';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function Header({ updateFiltersState }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    if (typeof updateFiltersState === 'function') {
      updateFiltersState({ description: e.target.value });
    }
    console.log("input : ",e.target.value);
  };

  useEffect(() => {
    const searchBtn = document.querySelector('.searchBtn');
    const closeBtn = document.querySelector('.closeBtn');
    const searchBox = document.querySelector('.searchBox');
    const navigation = document.querySelector('.navigation');
    const menuToggle = document.querySelector('.menu-toggle');
    const header = document.querySelector('header');
    
    const handleSearchClick = () => {
      searchBox.classList.add('active');
      closeBtn.classList.add('active');
      searchBtn.classList.add('inactive');
      header.classList.remove('open');
    };


    const handleCloseClick = () => {
      searchBox.classList.remove('active');
      closeBtn.classList.remove('active');
      searchBtn.classList.remove('inactive');
    };

    const handleMenuToggleClick = () =>{
        header.classList.toggle('open');
        searchBox.classList.remove('active');
      closeBtn.classList.remove('active');
      searchBtn.classList.remove('inactive');
        console.log("header  : ",header)
        console.log("on toggle clic")
    }

    searchBtn.addEventListener('click', handleSearchClick);
    closeBtn.addEventListener('click', handleCloseClick);
    menuToggle.addEventListener('click', handleMenuToggleClick);


    return () => {
      searchBtn.removeEventListener('click', handleSearchClick);
      closeBtn.removeEventListener('click', handleCloseClick);
      menuToggle.removeEventListener('click', handleMenuToggleClick);
    };
  }, []);

  useEffect(() => {
    // Check if the user is logged in (you should replace this with the actual logic)
    const user = JSON.parse(localStorage.getItem('dem_user'));
    const tokens = JSON.parse(localStorage.getItem('dem_tokens'));

    const decodedAccessToken = tokens && jwtDecode(tokens?.access);
      const currentTime = Date.now() / 1000;
      const isValidAccessToken = decodedAccessToken?.exp > currentTime;
    if(tokens && tokens.access && isValidAccessToken){
      setIsLoggedIn(!!user);
    }
  }, []);

  const handleLogout = () => {
    // Perform the logout actions (you should replace this with the actual logout logic)
    localStorage.removeItem('dem_user');
    localStorage.removeItem('dem_tokens');
    setIsLoggedIn(false);
    // navigate('/login'); // Redirect to the login page after logout
  };

  const handleMenuToggleClick = () => {
    setShowMenu(prevShowMenu => !prevShowMenu);
  };

  return (
    <header>
      <Link to="/" className="logo"></Link>
      <div className={`group ${showMenu ? 'show-menu' : ''}`}>
        <ul className="navigation">
          <li>
            <NavLink to="/">Accueil</NavLink>
          </li>
          <li>
            <NavLink to="/adverts">Annonces</NavLink>
          </li>
          {isLoggedIn ? (
           <>
           <li>
              <NavLink to="/dashboard">Mon tableau</NavLink>
            </li>
            {/* <li>
              <NavLink to="/profil">Profil</NavLink>
            </li> */}
            <li>
              <span  className={`logout`} onClick={handleLogout}>Deconnexion</span>
            </li>
           </>
          ) : (
            <li>
              <NavLink to="/login">Connexion</NavLink>
            </li>
          )}
        </ul>
        <div className="search">
          <span className="icon">
            <i className="bx bx-search searchBtn"></i>
            <i className="bx bx-x closeBtn"></i>
          </span>
        </div>
        <i className='bx bx-menu menu-toggle' onClick={handleMenuToggleClick}></i>
      </div>
      <DarkMode />
      <div className={`searchBox ${showMenu ? 'show-menu' : ''}`}>
        <input type="text" placeholder="Rechercher une chambre ..." value={searchInput}
        onChange={handleInputChange}/>
      </div>
    </header>
  );
}

export default Header;

import React, { useState } from "react";
import "./Adverts.css";
import DefaultButton from "../DefaultButton/DefaultButton";
import AdvertList from "./AdvertList";
import { Link } from "react-router-dom";

function Adverts({limit}) {
  const [loading, setLoading] = useState(true);

  // Function to update the loading state
  const updateLoadingState = (isLoading) => {
    setLoading(isLoading);
  };

  return (
    <>
      <div className={`adverts-section  ${loading ? 'loading' : ''}`}>
        <h1 className="section-title">Nos dernières annonces</h1>
        <AdvertList updateLoadingState={updateLoadingState} limit={limit}/>
        <div className="more-button">
          <Link to="/adverts">
            <DefaultButton>Cliquez ici pour voir toutes les annonces</DefaultButton>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Adverts;

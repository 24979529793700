import React from 'react';
import img from '../../common/assets/img/auth-bg.jpg';
import './UserAvatar.css';

function UserAvatar({user}) {
  console.log("user : ",user);
  return (
    <div className="user-avatar-container">
      <div className="user-avatar">
        <img src={user?.profile ?? img} alt={`${user?.first_name} ${user?.last_name}`} />
      </div>
      <h5>{user?.first_name} {user?.last_name}</h5>
    </div>
  );
}

export default UserAvatar;

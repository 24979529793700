export function calculerDatePrecedente(duree) {
    const currentDate = new Date();
  
    if (duree === '1-hour') {
        return new Date(currentDate.getTime() - 60 * 60 * 1000).toISOString();
      } else if (duree === '24-hour') {
        return new Date(currentDate.getTime() - 24 * 60 * 60 * 1000).toISOString();
      } else if (duree === '7-days') {
        return new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
      } else if (duree === '30-days') {
        return new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
      } else {
        return null;
      }
  }

  export const numberToCurrency = (input) => {
    const value = `${input}`;
    let arr = value.split('').reverse();
    let res = [];

    let counter = 0;
    for (let index = 0; index < arr.length; index++) {
        const char = arr[index];
        res.push(char);
        counter += 1;

        if (counter == 3) {
            res.push(' ');
            counter = 0;
        }
    }

    //console.log(res);

    return res.reverse().join('');

}

export const getDomainFromURL = (url = window.location.href) => {
  const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im);
  return match && match[1];
}


export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useApi from '../../../services/api';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useNavigate } from 'react-router-dom';


function RegisterForm() {
  const { register, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const api = useApi();
  const [phoneData, setPhoneData] = useState({});
  const navigate = useNavigate();



  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleFormSubmit = async (data) => {
    data.tel = phoneData.int;
    data.username = phoneData.nat;
    try {
      await api.register(data);
      navigate("/login", { state: { registrationSuccess: true } });
    } catch (error) {
      setRegisterError(error.message);
    }
  };


  function handleChange(_, nationalPhone, locationObj,intPhone) {
    setPhoneData({int:intPhone,nat:nationalPhone});
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {registerError && <span className="error-message">{registerError}</span>}
      <div className="form-group">
        <label htmlFor="name-input">Nom *</label>
        <input
          type="text"
          id="name-input"
          className="input-field"
          placeholder="John Doe"
          {...register('first_name', { required: true })}
        />
        {errors.name && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group">
        <label htmlFor="last-name-input">Prénom *</label>
        <input
          type="text"
          id="last-name-input"
          className="input-field"
          placeholder="Doe"
          {...register('last_name', { required: true })}
        />
        {errors.lastName && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group">
        <label htmlFor="email-input">Email</label>
        <input
          type="email"
          id="email-input"
          className="input-field"
          placeholder="example@gmail.com"
          {...register('email')}
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone-input">Téléphone *</label>
        <IntlTelInput
        preferredCountries={['tg']}
        onPhoneNumberChange={handleChange}
    />
        {errors.phone && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group">
        <label htmlFor="profile-select">Profil *</label>
        <select id="profile-select" className="input-field" {...register('typeUser', { required: true })}>
          <option value="">Sélectionnez un profil</option>
          <option value="chercheur">Particulier</option>
          <option value="demarcheur">Démarcheur</option>
          {/* <option value="agent">Agent Immobilier</option> */}
          <option value="conducteur">Conducteur</option>
        </select>
        {errors.profile && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group">
        <label htmlFor="password-input">Mot de passe *</label>
        <div className="password-input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password-input"
            className="input-field"
            placeholder="****************"
            {...register('password', { required: true })}
          />
          <i
            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} password-toggle-icon`}
            onClick={handleTogglePassword}
          ></i>
        </div>
        {errors.password && <span className="error-message">Ce champ est requis</span>}
      </div>
      <div className="form-group">
        <label htmlFor="confirm-password-input">Confirmer le mot de passe *</label>
        <input
          type="password"
          id="confirm-password-input"
          className="input-field"
          placeholder="****************"
          {...register('confirmPassword', {
            required: true,
            validate: (value) => value === watch('password') || 'Les mots de passe ne correspondent pas'
          })}
        />
        {errors.confirmPassword && <span className="error-message">{errors.confirmPassword.message}</span>}
      </div>
      <div className="form-group checkbox-group">
        <input
          type="checkbox"
          id="terms-checkbox"
          {...register('acceptTerms', { required: true })}
        />
        <label htmlFor="terms-checkbox">&nbsp;J'accepte les termes et conditions *</label>
        {errors.acceptTerms && <><br /><span className="error-message">Vous devez accepter les termes et conditions</span></>}
      </div>
      <button className="submit-button" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Inscription en cours...' : "S'inscrire"}
      </button>
    </form>
  );
}

export default RegisterForm;

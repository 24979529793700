import React from "react";
import ContentWrapper from "../content-wrapper/ContentWrapper";
import "./Hero.css";
import DefaultButton from "../DefaultButton/DefaultButton";
import { Link } from "react-router-dom";
function Hero() {
  return (
    // <ContentWrapper>
      <div className="hero-container">
        <div className="content">
          <h1 className="hero-title">Bienvenu sur Allô Démarcheur !</h1>
          <p className="hero-description">
            Désormais, trouver une maison en location au togo devient très facile
          </p>
          <div className="hero-actions">
          <Link to="/adverts">
            <DefaultButton>Je cherche une chambre <i className="fas fa-chevron-right"></i><i className="fas fa-chevron-right"></i></DefaultButton>
          </Link>
          </div>
        </div>
      </div>
    // </ContentWrapper>
  );
}

export default Hero;

// index.js

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

// Creation de la racine
const container = document.getElementById("root");
const root = createRoot(container);

// Render de l'application
root.render(<App />);

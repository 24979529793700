import React from 'react';
import AuthComponent from '../../../components/auth/AuthComponent';
import LoginForm from './LoginForm';

const loginComponent = {
  title: "S'identifier",
  subtitle: "Connectez-vous pour continuer",
  anotherLinkLabel: "S'inscrire",
  anotherLinkPath: "/register",
  description: "Nous sommes heureux de vous revoir",
};

function Login() {
  const handleLoginFormSubmit = (data) => {
    console.log("Login form data : ",data);
  };

  return (
    <AuthComponent {...loginComponent} form={<LoginForm onSubmit={handleLoginFormSubmit} />} />
  );
}

export default Login;
